import React from 'react'

const FluxibleIconBlock = ({ icon, text, link, size = 'sm' }) => {
  const iconString = Array.isArray(icon) ? `${icon[0]} fa-${icon[1]}` : `fa fa-${icon}`;
  return (
    <>
        <span className="icon">
            <i className={`${iconString} fa-${size}`}></i>
        </span>
        <span className="fluxible_icon_block">
            { link ? (<a href={link}>{text}</a>) : text }
        </span>
    </>
  )
}

const FluxibleSocialMediaBlock = ({ icon, link }) => {
  return (
    <a href={link}>
      <span className="icon">
        <i className={`${icon} fa-2x`}></i>
      </span>
    </a>
  )
}

export { FluxibleIconBlock, FluxibleSocialMediaBlock }
