import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/FluxibleHeaderLogo2x.png'
import { FluxibleIconBlock } from './FluxibleIcon'

class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.handleBackClick = this.handleBackClick.bind(this)
  }

  handleBackClick(e) {
    if (window.location.pathname.includes('events/')) {
      if (window.sessionStorage.getItem('scheduleScroll')) {
        window.sessionStorage.setItem('goBack', 'true')
      }
      document.getElementById('fluxible-logo-link').click()
    } else if (window.sessionStorage.getItem('eventLoaded')) {
      window.history.back()
    } else {
      document.getElementById('fluxible-logo-link').click()
    }
  }

  render() {
    return (
      <nav className="navbar is-transparent sticky" id="navbar">
        <div className="container">
          <div className="navbar-brand">
            {this.props.showBackArrow && (
              <button
                className="navbar-item back-arrow"
                onClick={this.handleBackClick}
              >
                <FluxibleIconBlock icon="arrow-left" size="2x" />
              </button>
            )}
            <Link
              to="/"
              className="navbar-item fluxible-logo-link center-logo"
              id="fluxible-logo-link"
            >
              <img src={logo} alt="Fluxible" className="fluxible-logo" />
            </Link>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
