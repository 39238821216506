import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import favIcon from '../img/favicon.ico'

const Header = ({ data }) => (
  <Helmet
    titleTemplate={`%s | ${data.site.siteMetadata.title}`}
    defaultTitle={data.site.siteMetadata.title}
  >
    <link rel="shortcut icon" href={favIcon} type="image/x-icon" />
    <link rel="apple-touch-icon" href="/icons/icon-512x512.png" />
  </Helmet>
)

const HeaderComponent = () => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <Header data={data} />}
  />
)

export default HeaderComponent