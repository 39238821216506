import React from 'react'
import PropTypes from 'prop-types'

import Header from '../components/Header'
import '../layouts/all.sass'
import '../layouts/style.sass'

const Layout = ({ children }) => (
  <div id="top-of-page">
    <Header />
    <div className="container">
      {/* <div className="fluxible-columns is-mobile is-centered">
        <div className="column is-12-mobile is-8-tablet"> */}
      <div>{children}</div>
      {/* </div>
      </div> */}
    </div>
  </div>
)

Layout.propTypes = {
  children: PropTypes.object,
}

export default Layout
